import { observer } from 'mobx-react';
import { Header } from '../../components/Header/Header';
import { PageTopPanel } from '../../components/PageTopPanel/PageTopPanel';
import { useNavigate } from 'react-router';
import { TemplatesListTable } from './components/TemplatesListTable';

export const TemplatesPage = observer(() => {
  const navigate = useNavigate();

  return (
    <div className="TemplatesPage">
      <Header />
      <PageTopPanel
        title="Templates"
        buttonText="Create new template"
        onButtonClick={() => navigate('/templates/create')}
      />
      <div className="TemplatesPage__content">
        <TemplatesListTable />
      </div>
    </div>
  );
});
