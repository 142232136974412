import { ContextMenu, ContextMenuFloatingProps } from "./ContextMenu";
import { ContextMenuItem } from "./ContextMenuItem";
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as TemplateIcon } from '../assets/icons/template.svg';
import { ReactComponent as DuplicateIcon } from '../assets/icons/duplicate.svg';
import { ReactComponent as MoveIcon } from '../assets/icons/move.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { forwardRef } from "react";

export const InvoiceContextMenu = forwardRef<HTMLDivElement, {
} & ContextMenuFloatingProps>(function InvoiceContextMenu({
	...props
}, forwardedRef) {
	return (
		<ContextMenu
			ref={forwardedRef}
			{...props}
		>
			<ContextMenuItem
				iconChildren={<EditIcon />}
			>
				Edit
			</ContextMenuItem>

			<ContextMenuItem
				iconChildren={<TemplateIcon />}
			>
				Save as template
			</ContextMenuItem>

			<ContextMenuItem
				iconChildren={<DuplicateIcon />}
			>
				Duplicate
			</ContextMenuItem>

			<ContextMenuItem
				iconChildren={<MoveIcon />}
			>
				Move
			</ContextMenuItem>

			<ContextMenuItem
				iconChildren={<DownloadIcon />}
			>
				Download
			</ContextMenuItem>

			<ContextMenuItem
				iconChildren={<DeleteIcon />}
			>
				Delete
			</ContextMenuItem>
		</ContextMenu>
	);
});
