import useComponentVisible from '../../../hooks/useComponentVisible';

type SelectOption = {
  label: string;
  value: string | number;
};

interface ISelectProps {
  value?: string | number;
  options?: SelectOption[];
}

export const Select = ({ value, options }: ISelectProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const toggleVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className="Select" ref={ref}>
      <div className="SelectInput" onClick={toggleVisibility}>
        {value}
        <div className="Icon arrow-down" />
      </div>
      {isComponentVisible ? (
        <div className="Select__options">
          {options?.map(_ => (
            <div className="Select__option">{_.label}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
