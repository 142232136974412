import { observer } from 'mobx-react';
import { useStore } from '../../../stores';
import { InvoiceItem } from '../../../stores/InvoiceStore';
import { Input } from '../../../components/controls/Input/Input';

export const InvoiceItems = observer(() => {
  const store = useStore();
  const { invoiceStore } = store;
  const { form } = invoiceStore;

  return (
    <div className="InvoiceItems">
      <div className="InvoiceItems__title">Items</div>
      <div className="InvoiceItems__list">
        {form.items.map(item => {
          return (
            <InvoiceItemElement
              key={item.uid}
              onChangeItemPlace={form.onChangeItemPlace}
              onDeleteClick={form.onDeleteItemClick}
              item={item}
            />
          );
        })}
      </div>
      <button className="Button secondary" onClick={form.onNewItemClick}>
        <div className="Icon plus" />
        Add item
      </button>
    </div>
  );
});

const InvoiceItemElement = observer(
  ({
    item,
    onDeleteClick,
    onChangeItemPlace,
  }: {
    item: InvoiceItem;
    onDeleteClick: (place: number) => void;
    onChangeItemPlace: (item: InvoiceItem, newPlace: number) => void;
  }) => {
    return (
      <div className="InvoiceItem">
        <div className="InvoiceItem__place">
          <div className="InvoiceItem__place__arrow" onClick={() => onChangeItemPlace(item, item.place + 1)}>
            <div className="Icon arrow-up small" />
          </div>
          <div className="InvoiceItem__place__place">{item.place}</div>
          <div className="InvoiceItem__place__arrow" onClick={() => onChangeItemPlace(item, item.place - 1)}>
            <div className="Icon arrow-down small" />
          </div>
        </div>
        <div className="InvoiceItem__name">
          <Input value={item.name} onChange={item.onChangeName} label="Item" placeholder="Enter item name" />
        </div>
        <div className="InvoiceItem__default">
          <Input
            value={item.rate}
            onChange={item.onChangeRate}
            type="number"
            label="Rate"
            placeholder="0.00"
            watermark="$"
          />
        </div>
        <div className="InvoiceItem__default">
          <Input
            value={item.quantity}
            onChange={item.onChangeQuantity}
            type="number"
            label="Quantity"
            placeholder="-"
          />
        </div>
        <div className="InvoiceItem__default">
          <Input
            value={item.amount ? String(item.amount) : undefined}
            type="number"
            disabled
            label="Amount"
            placeholder="0.00"
            watermark="$"
          />
        </div>
        <button className="Button red" onClick={() => onDeleteClick(item.place)}>
          <div className="Icon delete" />
        </button>
      </div>
    );
  },
);
