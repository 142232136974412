import PDFDocument from 'pdfkit';
import './registerStaticPdfKitFiles.ts';
import { InvoiceForm } from '../../InvoiceStore.js';
import { toBase64 } from '../toBase64';
import { formatAmount } from '../formatAmount';

const blobStream = require('blob-stream');

const docPadding = 24;
const defaultFontSize = 8;

export async function createInvoice(invoice: InvoiceForm): Promise<string> {
  return await new Promise(async (resolve, reject) => {
    try {
      const doc = new PDFDocument({ size: 'A4', margin: docPadding });
      const stream = doc.pipe(blobStream());
      doc
        .registerFont('Manrope-Regular', 'Manrope-Regular.ttf')
        .registerFont('Manrope-Bold', 'Manrope-Bold.ttf')
        .registerFont('Manrope-Medium', 'Manrope-Medium.ttf');
      await generateHeader(doc, invoice);
      await generateInvoiceNum(doc, invoice);
      await generateBillInfo(doc, invoice);
      await generatePaymentDetails(doc, invoice);
      await generateInvoiceTable(doc, invoice);
      doc.end();
      stream
        .on('finish', function () {
          console.debug(stream.toBlobURL('application/pdf'));
          resolve(stream.toBlobURL('application/pdf'));
        })
        .on('error', () => {
          resolve('');
        });
    } catch (e) {
      console.error(e);
      reject('');
    }
  });
}

async function generateHeader(doc: PDFKit.PDFDocument, invoice: InvoiceForm) {
  if (invoice.logo) {
    const logoBase64 = await toBase64(invoice.logo);
    if (logoBase64) {
      doc.image(logoBase64, docPadding, 30, { width: 110 });
    }
  }

  const datesXPos = 420;
  const datesYPos = docPadding;
  doc
    .font('Manrope-Medium')
    .fillColor('#6A6A6A')
    .fontSize(defaultFontSize)
    .text('Issued on', datesXPos + 12, datesYPos)
    .text('Due on', datesXPos + 100, datesYPos)
    .font('Manrope-Regular')
    .fillColor('#000000')
    .text(invoice.formattedIssuedOnDate, datesXPos + 12, datesYPos + 16)
    .text(invoice.formattedDueDate, datesXPos + 100, datesYPos + 16);
}

async function generateInvoiceNum(doc: PDFKit.PDFDocument, invoice: InvoiceForm) {
  doc
    .font('Manrope-Bold')
    .fontSize(14)
    .text('Invoice #' + invoice.invoiceNumber, docPadding, 80);
}

async function generateBillInfo(doc: PDFKit.PDFDocument, invoice: InvoiceForm) {
  const billInfoY = 120;

  doc
    .font('Manrope-Medium')
    .fillColor('#6A6A6A')
    .fontSize(defaultFontSize)
    .text('Bill from', docPadding, billInfoY)
    .text('Bill to', 250, billInfoY)
    .font('Manrope-Regular')
    .fillColor('#000000')
    .text(invoice.billFrom, docPadding, billInfoY + 14)
    .text(invoice.billTo, 250, billInfoY + 14);
}

async function generatePaymentDetails(doc: PDFKit.PDFDocument, invoice: InvoiceForm) {
  const paymentDetailsYPos = 220;
  doc
    .font('Manrope-Medium')
    .fillColor('#6A6A6A')
    .text('Payment details', docPadding, paymentDetailsYPos)
    .font('Manrope-Regular')
    .fillColor('#000000')
    .text(invoice.paymentDetails, docPadding, paymentDetailsYPos + 14);
}

async function generateInvoiceTable(doc: PDFKit.PDFDocument, invoice: InvoiceForm) {
  let i;
  const invoiceTableTop = 350;

  doc.font('Manrope-Medium').fillColor('#6A6A6A');
  generateHr(doc, invoiceTableTop - 8);

  generateTableRow(doc, invoiceTableTop, 'Service', 'Quantity', 'Rate', 'Amount');
  generateHr(doc, invoiceTableTop + 20);
  doc.font('Manrope-Regular').fillColor('#000000');

  for (i = 0; i < invoice.items.length; i++) {
    const item = invoice.items[i];
    const position = invoiceTableTop + (i + 1) * 26;
    generateTableRow(
      doc,
      position,
      item.name,
      item.quantity ?? '0',
      formatAmount(item.rate ?? ''),
      formatAmount(item.amount),
    );

    generateHr(doc, position + 20);
  }

  const subtotalPosition = invoiceTableTop + (i + 1) * 26;
  generateTableRow(doc, subtotalPosition, '', '', 'Subtotal', formatAmount(invoice.subtotalItemsAmount));

  const taxesPosition = subtotalPosition + 20;
  generateTableRow(doc, taxesPosition, '', '', 'Taxes', `${formatAmount(invoice.taxAmount)}`);

  const discountPosition = taxesPosition + 20;
  generateTableRow(doc, discountPosition, '', '', 'Discount', `${formatAmount(invoice.discountAmount)}`);

  generateHr(doc, discountPosition + 20);

  const totalPosition = discountPosition + 30;
  doc.font('Manrope-Bold');
  generateTableRow(doc, totalPosition, '', '', 'Total', formatAmount(invoice.totalAmount));
  doc.font('Manrope-Regular');
  generateHr(doc, totalPosition + 17);
  generateFooter(doc, invoice, totalPosition + 40);
}

function generateFooter(doc: PDFKit.PDFDocument, invoice: InvoiceForm, footerYPosition: number) {
  doc
    .font('Manrope-Medium')
    .fillColor('#6A6A6A')
    .fontSize(defaultFontSize)
    .text('Notes', docPadding, footerYPosition)
    .text('Terms', 300, footerYPosition)
    .font('Manrope-Regular')
    .fillColor('#000000')
    .text(invoice.notes, docPadding, footerYPosition + 16, { width: 250 })
    .text(invoice.terms, 300, footerYPosition + 16);
}

function generateTableRow(
  doc: PDFKit.PDFDocument,
  y: number,
  service: string,
  quantity: string,
  rate: string,
  amount: string,
) {
  doc
    .text(service, docPadding, y)
    .text(quantity, 310, y, { width: 90, align: 'right' })
    .text(rate, 380, y, { width: 90, align: 'right' })
    .text(amount, 460, y, { width: 90, align: 'right' });
}

function generateHr(doc: PDFKit.PDFDocument, y: number) {
  doc.strokeColor('#CDCDCD').lineWidth(1).moveTo(24, y).lineTo(570, y).stroke();
}
