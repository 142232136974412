import { NavLink } from 'react-router-dom';

export const Header = () => {
  return (
    <header className="Header">
      <div className="Header__left">
        <div className="Header__logo" />
        <div className="Header__menu">
          <NavLink to={'/invoices'} className={({ isActive }) => `Header__menu__item ${isActive ? 'active' : ''}`}>
            Invoices
          </NavLink>
          <NavLink to={'/templates'} className={({ isActive }) => `Header__menu__item ${isActive ? 'active' : ''}`}>
            Templates
          </NavLink>
          <div className="Header__menu__item">Contacts</div>
          <div className="Header__menu__item">API</div>
        </div>
      </div>
      <div className="Header__right">
        <div className="Header__plan">
          <div className="Header__plan__name">
            <div className="label">Plan</div>
            <div className="name">Free</div>
          </div>
          <div className="Header__plan__right">
            <div className="left-invoices">Left 0 invoices</div>
            <div className="upgrade-label">Upgrade</div>
            <div className="Icon arrow-right small" />
          </div>
        </div>
        <div className="Header__userInfo">
          <div className="Header__userInfo__avatar">NA</div>
          <div className="Header__userInfo__info">
            <div className="fullName">Full Name</div>
            <div className="mail">mail@gmail.com</div>
          </div>
          <div className="Icon arrow-down small" />
        </div>
      </div>
    </header>
  );
};
