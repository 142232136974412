export const PageTopPanel = ({
  title,
  buttonText,
  onButtonClick,
}: {
  title: string;
  buttonText: string;
  onButtonClick: () => void;
}) => {
  return (
    <div className="PageTopPanel">
      <div className="PageTopPanel__title">{title}</div>
      <button className="Button primary" onClick={onButtonClick}>
        <div className="Icon plus-white" />
        {buttonText}
      </button>
    </div>
  );
};
