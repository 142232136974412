import fs from 'fs';
import Helvetica from './Helvetica.afm';
import ManropeRegular from './static-assets/Manrope-Regular.ttf';
import ManropeBold from './static-assets/Manrope-Bold.ttf';
import ManropeMedium from './static-assets/Manrope-Medium.ttf';

function registerBinaryFiles(ctx: any) {
  ctx.keys().forEach((key: any) => {
    // extracts "./" from beginning of the key
    fs.writeFileSync(key.substring(2), ctx(key));
  });
}
fs.writeFileSync('data/Helvetica.afm', Helvetica);
fs.writeFileSync('Manrope-Regular.ttf', ManropeRegular);
fs.writeFileSync('Manrope-Bold.ttf', ManropeBold);
fs.writeFileSync('Manrope-Medium.ttf', ManropeMedium);

//registerBinaryFiles(require.context('./static-assets', true));
