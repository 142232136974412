import { observer } from 'mobx-react';
import { Header } from '../../components/Header/Header';
import { InvoiceTopPanel } from './components/InvoiceTopPanel';
import { InvoiceForm } from './components/InvoiceForm';
import { useStore } from '../../stores';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const InvoicePage = observer(() => {
  const store = useStore();
  const { invoiceStore } = store;
  const { pdfUrl } = invoiceStore;
  return (
    <div className="InvoicePage">
      <Header />
      <InvoiceTopPanel />
      <div className="InvoicePage__content">
        <InvoiceForm />
        <div>
          <Worker workerUrl="/js/unpkg.com_pdfjs-dist@3.4.120_build_pdf.worker.min.js">
            {pdfUrl && <Viewer fileUrl={pdfUrl ?? ''} />}
          </Worker>
        </div>
      </div>
    </div>
  );
});
