import { ChangeEvent } from 'react';

type TextAreaProps = {
  label: string;
  value?: string;
  rows?: number;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const TextArea = ({ label, value, rows, onChange, placeholder }: TextAreaProps) => {
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value ?? '');
  };
  return (
    <div className="TextArea">
      <div className="TextArea__label">{label}</div>
      <textarea value={value} rows={rows ?? 4} placeholder={placeholder} onChange={onInputChange} />
    </div>
  );
};
