import { ReactNode } from 'react';
import styles from './ContextMenuItem.module.scss';

export function ContextMenuItem({
	iconChildren,
	children,
}: {
	iconChildren: ReactNode;
	children: ReactNode;
}) {
	return (
		<div
			className={styles.ContextMenuItem}
		>
			{iconChildren}
			<span
				className={styles.ContextMenuItem__text}
			>
				{children}
			</span>
		</div>
	);
}
