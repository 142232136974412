import { ChangeEvent } from 'react';
import cn from 'classnames';

type InputProps = {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  type?: string;
  watermark?: string;
  disabled?: boolean;
  maxLength?: number;
  iconName?: string;
  className?: string;
};

export const Input = ({
  label,
  iconName,
  type,
  maxLength,
  disabled,
  value,
  onChange,
  placeholder,
  watermark,
  className,
}: InputProps) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value ?? '');
  };
  return (
    <div className={cn('Input', className, { disabled: disabled })}>
      {label ? <div className="Input__label">{label}</div> : null}
      <div className="Input__container">
        {iconName ? <div className={cn('Icon Input__icon', iconName)} /> : null}
        {watermark ? <span>{watermark}</span> : null}
        <input
          maxLength={maxLength}
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
};
