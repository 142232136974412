import { cloneElement, ReactElement, useState } from "react";
import { offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';

export function ContextMenuTrigger({
	triggerChildren: triggerChildrenProp,
	children: childrenProp,
}: {
	triggerChildren: ReactElement;
	children: ReactElement;
}) {
	const [ open, setOpen ] = useState(false);

	const { refs, floatingStyles, context } = useFloating({
		open,
		onOpenChange: setOpen,
		placement: 'bottom-end',
		middleware: [
			offset(20),
			shift(),
		],
	});

	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([
		dismiss,
	]);

	const triggerChildren = cloneElement(triggerChildrenProp, {
		ref: refs.setReference,
		onClick: () => setOpen(o => !o),
		...getReferenceProps(),
	});

	const children = cloneElement(childrenProp, {
		ref: refs.setFloating,
		style: floatingStyles,
		...getFloatingProps(),
	});

	return (
		<>
			{triggerChildren}

			{open && children}
		</>
	);
}
