import { Input } from '../../../components/controls/Input/Input';
import { Select } from '../../../components/controls/Select/Select';

export const TemplatesListTable = () => {
  return (
    <div className="TemplatesListTable">
      <Input className="TemplatesListTable__searchInput" iconName="search" placeholder="Search" />
      <div className="Table__container">
        <table className="Table">
          <thead>
            <th>Template name</th>
            <th>Created date</th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>{'{$template-name}'}</td>
              <td>18.07.2023, 21:32</td>
              <td>
                <div className="Table__actionButtonsContainer">
                  <div className="TemplatesListTable__useButton">
                    <div className="Icon duplicate small" />
                    Use
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{'{$template-name}'}</td>
              <td>18.07.2023, 21:32</td>
              <td>
                <div className="Table__actionButtonsContainer">
                  <div className="TemplatesListTable__useButton">
                    <div className="Icon duplicate small" />
                    Use
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{'{$template-name}'}</td>
              <td>18.07.2023, 21:32</td>
              <td>
                <div className="Table__actionButtonsContainer">
                  <div className="TemplatesListTable__useButton">
                    <div className="Icon duplicate small" />
                    Use
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{'{$template-name}'}</td>
              <td>18.07.2023, 21:32</td>
              <td>
                <div className="Table__actionButtonsContainer">
                  <div className="TemplatesListTable__useButton">
                    <div className="Icon duplicate small" />
                    Use
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="Table__footer">
          <div className="Table__footer__left">
            <div className="Table__footer__rows-per-page">
              Rows per page
              <Select
                value={10}
                options={[
                  { value: 10, label: '10' },
                  { value: 100, label: '100' },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
