import { ChangeEvent, useRef } from 'react';

type FileInputProps = {
  label: string;
  file?: File;
  onSetFile: (file: File) => void;
  onDeleteFile: () => void;
};

export const FileInput = ({ label, file, onSetFile, onDeleteFile }: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onSetFile(e.target.files[0]);
    }
  };
  const onInputBlockClick = () => {
    inputRef?.current?.click();
  };
  return (
    <div className="FileInput__container">
      <div className="FileInput__label">{label}</div>
      <div className="FileInput">
        <div className="FileInput__input" onClick={onInputBlockClick}>
          <div className="Icon upload big" />
          <div className="FileInput__input__info">
            <div className="FileInput__input__label">Upload new file</div>
            <div className="FileInput__input__description">PNG, JPG, JPEG, less than 10 MB</div>
          </div>
          <input type="file" ref={inputRef} onChange={inputChange} />
        </div>
        {file ? (
          <div className="FileInput__preview">
            <img alt="" src={URL.createObjectURL(file)} />
            <div className="Icon delete" onClick={onDeleteFile} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
