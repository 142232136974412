import { makeAutoObservable } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import { InvoiceStore } from './InvoiceStore';

export class AppState {
  invoiceStore = new InvoiceStore();

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new AppState();

export function useStore(): AppState {
  return React.useContext(MobXProviderContext).store;
}
