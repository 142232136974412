import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

interface IDatepickerProps {
  label: string;
  value?: Date;
  onChange: (value: Date) => void;
}

export const Datepicker = ({ value, label, onChange }: IDatepickerProps) => {
  return (
    <div className="Datepicker">
      <div className="Datepicker__label">{label}</div>
      <div className="Datepicker__container">
        <div className="Icon calendar" />
        <DatePicker placeholderText="Select date" selected={value ? value : undefined} onChange={onChange} />
      </div>
    </div>
  );
};
