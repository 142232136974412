import { observer } from 'mobx-react';
import { useStore } from '../../../stores';
import { formatAmount } from '../../../stores/utils/formatAmount';
import { Input } from '../../../components/controls/Input/Input';

export const InvoiceTotals = observer(() => {
  const store = useStore();
  const { invoiceStore } = store;
  const { form } = invoiceStore;

  return (
    <div className="InvoiceTotals">
      <div className="InvoiceTotals__row">
        <div className="InvoiceTotals__label">Subtotal</div>
        <div className="InvoiceTotals__value">{formatAmount(form.subtotalItemsAmount)}</div>
      </div>
      <div className="InvoiceTotals__row">
        <div className="InvoiceTotals__label">Discount, %</div>
        <Input value={form.discountPercent} maxLength={2} onChange={form.onChangeDiscountPercent} />
        <div className="InvoiceTotals__value">{formatAmount(form.discountAmount)}</div>
      </div>
      <div className="InvoiceTotals__row">
        <div className="InvoiceTotals__label">Taxes, %</div>
        <Input value={form.taxPercent} maxLength={2} onChange={form.onChangeTaxPercent} />
        <div className="InvoiceTotals__value">{formatAmount(form.taxAmount)}</div>
      </div>
      <div className="InvoiceTotals__row">
        <div className="InvoiceTotals__label">Total</div>
        <div className="InvoiceTotals__value total">{formatAmount(form.totalAmount)}</div>
      </div>
    </div>
  );
});
