import classNames from 'classnames';
import { Input } from '../../../components/controls/Input/Input';
import { Select } from '../../../components/controls/Select/Select';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import styles from './InvoicesListTable.module.scss';
import { InvoiceContextMenu } from '../../../components/InvoiceContextMenu';
import { ContextMenuTrigger } from '../../../components/ContextMenuTrigger';
import { Popup } from '../../../components/Popup';

const mockData = [
	{
		invoiceNumber: '0032/05/2023',
		folder: 'Invoices 2023/06 / Apple',
		status: 'Sent',
		createdDate: '18.07.2023, 21:32',
	},
].flatMap((item) => {
	return Array.from({ length: 3 }, () => item);
});

export const InvoicesListTable = () => {
	return (
		<div className={styles.InvoicesListTable}>
			<div className={styles.InvoicesListTable__title}>
				All invoices
			</div>
			<Input
				className={styles.InvoicesListTable__search}
				iconName="search"
				placeholder="Search"
			/>
			<div className="Table__container">
				<table className="Table">
					<thead>
						<tr>
							<th>Invoice #</th>
							<th>Folder</th>
							<th>Status</th>
							<th>Created date</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{mockData.map((item, index) => (
							<tr
								key={index}
							>
								<td>{item.invoiceNumber}</td>
								<td>
									<span>{item.folder}</span>
								</td>
								<td>
									<div
										className={classNames(
											styles.InvoicesListTable__status,
											styles.InvoicesListTable__statusSent
										)}
									>
										{item.status}
									</div>
								</td>
								<td>{item.createdDate}</td>
								<td
									className={styles.InvoicesListTable__moreCell}
								>
									<div
										className={styles.InvoicesListTable__more}
									>
										<ContextMenuTrigger
											triggerChildren={<MoreIcon />}
										>
											<InvoiceContextMenu />
										</ContextMenuTrigger>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="Table__footer">
					<div className="Table__footer__left">
						<div className="Table__footer__rows-per-page">
							Rows per page
							<Select
								value={10}
								options={[
									{ value: 10, label: '10' },
									{ value: 100, label: '100' },
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
