import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { InvoicePage } from './pages/InvoicePage/InvoicePage';
import { InvoicesPage } from './pages/InvoicesPage/InvoicesPage';
import { TemplatesPage } from './pages/TemplatesPage/TemplatesPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/invoices" element={<InvoicesPage />} />
          <Route path="/invoices/create" element={<InvoicePage />} />
          <Route path="/templates" element={<TemplatesPage />} />

          <Route path="/" element={<Navigate to={'/invoices'} replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
