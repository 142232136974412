import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../stores';

export const InvoiceTopPanel = observer(() => {
  const store = useStore();
  const { invoiceStore } = store;

  return (
    <div className="InvoiceTopPanel">
      <div className="InvoiceTopPanel__left">
        <Link to="/invoices">
          <div className="InvoiceTopPanel__backButton">
            <div className="Icon arrow-left" />
            <div className="InvoiceTopPanel__backButton__label">Invoices / Invoices 04/2023</div>
          </div>
        </Link>
        <div className="InvoiceTopPanel__title">Create invoice</div>
      </div>
      <div className="InvoiceTopPanel__right">
        <button className="Button">Delete</button>
        <button className="Button secondary">Save as draft</button>
        <button className="Button primary" onClick={invoiceStore.savePdf}>
          Save and send
        </button>
      </div>
    </div>
  );
});
