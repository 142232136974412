import { observer } from 'mobx-react';
import { FileInput } from '../../../components/controls/FileInput/FileInput';
import { useStore } from '../../../stores';
import { TextArea } from '../../../components/controls/TextArea/TextArea';
import { Input } from '../../../components/controls/Input/Input';
import { Datepicker } from '../../../components/controls/Datepicker/Datepicker';
import { InvoiceItems } from './InvoiceItems';
import { InvoiceTotals } from './InvoiceTotals';

export const InvoiceForm = observer(() => {
  const store = useStore();
  const { invoiceStore } = store;
  const { form } = invoiceStore;
  const {
    onSetLogo,
    logo,
    onDeleteLogo,
    onChangeBillFrom,
    onChangeBillTo,
    billFrom,
    billTo,
    email,
    onChangeEmail,
    issuedOnDate,
    onChangeIssuedOnDate,
    dueOnDate,
    onChangeDueOnDate,
    paymentDetails,
    onChangePaymentDetails,
    notes,
    onChangeNotes,
    terms,
    invoiceNumber,
    onChangeInvoiceNumber,
    onChangeTerms,
  } = form;
  return (
    <div className="InvoiceForm">
      <div className="InvoiceForm__title">Details</div>
      <div className="InvoiceForm__row">
        <FileInput label="Logo" onSetFile={onSetLogo} file={logo} onDeleteFile={onDeleteLogo} />
      </div>
      <div className="InvoiceForm__row">
        <TextArea
          placeholder="Enter Legal Name and Address"
          value={billFrom}
          onChange={onChangeBillFrom}
          label="Bill from"
        />
        <TextArea placeholder="Enter Legal Name and Address" value={billTo} onChange={onChangeBillTo} label="Bill to" />
      </div>
      <div className="InvoiceForm__row">
        <Input placeholder="Enter recipient email" value={email} onChange={onChangeEmail} label="Recipient email" />
      </div>
      <div className="InvoiceForm__row">
        <Datepicker value={issuedOnDate} onChange={onChangeIssuedOnDate} label="Issued on" />
        <Datepicker value={dueOnDate} onChange={onChangeDueOnDate} label="Due on" />
      </div>
      <div className="InvoiceForm__row">
        <Input placeholder="Enter invoice #" value={invoiceNumber} onChange={onChangeInvoiceNumber} label="Invoice #" />
      </div>
      <div className="InvoiceForm__row">
        <TextArea
          placeholder="Enter payment details"
          value={paymentDetails}
          onChange={onChangePaymentDetails}
          label="Payment details"
        />
      </div>
      <div className="InvoiceForm__row">
        <TextArea placeholder="Enter note" rows={1} value={notes} onChange={onChangeNotes} label="Notes" />
        <TextArea placeholder="Enter terms" rows={1} value={terms} onChange={onChangeTerms} label="Terms" />
      </div>
      <div className="InvoiceForm__row">
        <InvoiceItems />
      </div>
      <InvoiceTotals />
    </div>
  );
});
