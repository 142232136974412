import { ReactComponent as FolderIcon } from '../../../assets/icons/folder.svg';

export const InvoicesPageFolders = () => {
  return (
    <div className="InvoicesPageFolders">
      <div className="InvoicesPageFolders__folder">
        <div className="InvoicesPageFolders__folder__item with-childrens">
          <div className="Icon arrow-down small" />
          <FolderIcon fill="#03170D" />
          <div className="InvoicesPageFolders__folder__name">Invoices 2023/06</div>
        </div>
        <div className="InvoicesPageFolders__folder child-1">
          <div className="InvoicesPageFolders__folder__item with-childrens">
            <div className="Icon arrow-down small InvoicesPageFolders__folder__icon-arrow-right" />
            <FolderIcon fill="#03170D" />
            <div className="InvoicesPageFolders__folder__name">Apple</div>
          </div>
        </div>
        <div className="InvoicesPageFolders__folder child-1">
          <div className="InvoicesPageFolders__folder__item with-childrens">
            <div className="Icon arrow-down small" />
            <FolderIcon fill="#03170D" />
            <div className="InvoicesPageFolders__folder__name">Microsoft</div>
          </div>
          <div className="InvoicesPageFolders__folder child-2">
            <div className="InvoicesPageFolders__folder__item active">
              <FolderIcon fill="#FFFFFF" />
              <div className="InvoicesPageFolders__folder__name">Development</div>
            </div>
          </div>
        </div>
      </div>
      <div className="InvoicesPageFolders__folder">
        <div className="InvoicesPageFolders__folder__item with-childrens">
          <div className="Icon arrow-down small InvoicesPageFolders__folder__icon-arrow-right" />
          <FolderIcon fill="#03170D" />
          <div className="InvoicesPageFolders__folder__name">Invoices 2023/06</div>
        </div>
      </div>
      <div className="InvoicesPageFolders__folder">
        <div className="InvoicesPageFolders__folder__item with-childrens">
          <div className="Icon arrow-down small InvoicesPageFolders__folder__icon-arrow-right" />
          <FolderIcon fill="#03170D" />
          <div className="InvoicesPageFolders__folder__name">Invoices 2023/06</div>
        </div>
      </div>
      <div className="InvoicesPageFolders__folder">
        <div className="InvoicesPageFolders__folder__item with-childrens">
          <div className="Icon arrow-down small InvoicesPageFolders__folder__icon-arrow-right" />
          <FolderIcon fill="#03170D" />
          <div className="InvoicesPageFolders__folder__name">Invoices 2023/06</div>
        </div>
      </div>
      <div className="InvoicesPageFolders__folder">
        <div className="InvoicesPageFolders__folder__item with-childrens">
          <div className="Icon arrow-down small InvoicesPageFolders__folder__icon-arrow-right" />
          <FolderIcon fill="#03170D" />
          <div className="InvoicesPageFolders__folder__name">Invoices 2023/06</div>
        </div>
      </div>
    </div>
  );
};
