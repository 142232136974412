import { observer } from 'mobx-react';
import { PageTopPanel } from '../../components/PageTopPanel/PageTopPanel';
import { Header } from '../../components/Header/Header';
import { InvoicesPageLeftPanel } from './components/InvoicesPageLeftPanel';
import { InvoicesListTable } from './components/InvoicesListTable';
import { useNavigate } from 'react-router';

export const InvoicesPage = observer(() => {
  const navigate = useNavigate();
  return (
    <div className="InvoicesPage">
      <Header />
      <PageTopPanel
        title="Invoices"
        buttonText="Create new invoice"
        onButtonClick={() => navigate('/invoices/create')}
      />
      <div className="InvoicesPage__content">
        <InvoicesPageLeftPanel />
        <InvoicesListTable />
      </div>
    </div>
  );
});
