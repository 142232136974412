import { ReactComponent as AllInvoicesIcon } from '../../../assets/icons/all-invoices.svg';
import { ReactComponent as DraftIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { InvoicesPageFolders } from './InvoicesPageFolders';

export const InvoicesPageLeftPanel = () => {
  return (
    <div className="InvoicesPageLeftPanel">
      <div className="InvoicesPageLeftPanel__tabs">
        <div className="InvoicesPageLeftPanel__tab active">
          <AllInvoicesIcon fill="#FFFFFF" />
          <div className="InvoicesPageLeftPanel__tab__name">All</div>
        </div>
        <div className="InvoicesPageLeftPanel__tab">
          <DraftIcon fill="#03170D" />
          <div className="InvoicesPageLeftPanel__tab__name">Drafts</div>
        </div>
        <div className="InvoicesPageLeftPanel__tab">
          <TrashIcon fill="#03170D" />
          <div className="InvoicesPageLeftPanel__tab__name">Trash</div>
        </div>
      </div>
      <div className="InvoicesPageLeftPanel__folders">
        <div className="InvoicesPageLeftPanel__folders__head">
          <div className="InvoicesPageLeftPanel__folders__head__title">Folders</div>
          <PlusIcon />
        </div>
        <InvoicesPageFolders />
      </div>
    </div>
  );
};
