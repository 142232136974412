import { autorun, makeAutoObservable, observe } from 'mobx';
import { createRef } from 'react';
import { createInvoice } from './utils/pdf-generator/create-invoice';
import moment from 'moment';
import { debounce } from './utils/debounce';

export interface IInvoiceItem {
  place: number;
  name: string;
  rate?: string;
  quantity?: string;
  uid: string;
}

export class InvoiceItem implements IInvoiceItem {
  place: number = 0;
  name: string = '';
  rate?: string | undefined;
  quantity?: string | undefined;
  uid: string;
  invoiceStore: InvoiceStore;

  constructor(invoiceStore: InvoiceStore, place: number) {
    this.uid = String(Math.random());

    this.invoiceStore = invoiceStore;
    makeAutoObservable(this);
    this.place = place;
    observe(this, () => {
      this.invoiceStore.updateInvoice();
    });
  }

  get amount(): string {
    if (this.quantity && this.rate) {
      return (Number(this.quantity) * Number(this.rate)).toFixed(2);
    }
    return '0';
  }

  onChangeName = (value: string) => {
    console.log(value);
    this.name = value;
  };
  onChangeRate = (value: string) => (this.rate = value);
  onChangeQuantity = (value: string) => (this.quantity = value);
}

export class InvoiceForm {
  logo?: File = undefined;
  billFrom: string = '';
  billTo: string = '';
  email: string = '';
  issuedOnDate?: Date = undefined;
  dueOnDate?: Date = undefined;
  invoiceNumber: string = '';
  paymentDetails: string = '';
  notes: string = '';
  terms: string = '';

  items: InvoiceItem[] = [];

  discountPercent: string = '';
  taxPercent: string = '';

  invoiceStore: InvoiceStore;

  constructor(invoiceStore: InvoiceStore) {
    makeAutoObservable(this);
    this.invoiceStore = invoiceStore;
  }

  get formattedIssuedOnDate() {
    return this.issuedOnDate ? moment(this.issuedOnDate).format('DD.MM.YYYY') : '';
  }

  get formattedDueDate() {
    return this.dueOnDate ? moment(this.dueOnDate).format('DD.MM.YYYY') : '';
  }

  get subtotalItemsAmount() {
    if (this.items?.length) {
      return this.items
        .reduce((amount: number, item: InvoiceItem) => {
          return amount + Number(item.amount);
        }, 0)
        .toFixed(2);
    }
    return 0;
  }

  get discountAmount() {
    if (this.subtotalItemsAmount && this.discountPercent) {
      return ((Number(this.subtotalItemsAmount) / 100) * Number(this.discountPercent)).toFixed(2);
    }
    return 0;
  }

  get taxAmount() {
    if (this.subtotalItemsAmount && this.taxPercent) {
      return ((Number(this.subtotalItemsAmount) / 100) * Number(this.taxPercent)).toFixed(2);
    }
    return 0;
  }

  get totalAmount() {
    if (this.subtotalItemsAmount) {
      return (Number(this.subtotalItemsAmount) - Number(this.discountAmount) - Number(this.taxAmount)).toFixed(2);
    }
    return 0;
  }

  onSetLogo = (img: File) => {
    this.logo = img;
  };

  onDeleteLogo = () => {
    this.logo = undefined;
  };

  onChangeIssuedOnDate = (value: Date) => (this.issuedOnDate = value);
  onChangeDueOnDate = (value: Date) => (this.dueOnDate = value);
  onChangeBillFrom = (value: string) => (this.billFrom = value);
  onChangeBillTo = (value: string) => (this.billTo = value);
  onChangeEmail = (value: string) => (this.email = value);
  onChangeInvoiceNumber = (value: string) => (this.invoiceNumber = value);
  onChangePaymentDetails = (value: string) => (this.paymentDetails = value);
  onChangeNotes = (value: string) => (this.notes = value);
  onChangeTerms = (value: string) => (this.terms = value);

  onChangeDiscountPercent = (value: string) => (this.discountPercent = value);
  onChangeTaxPercent = (value: string) => (this.taxPercent = value);

  onNewItemClick = () => {
    this.items = [...this.items, new InvoiceItem(this.invoiceStore, this.items.length)];
  };

  onDeleteItemClick = (place: number) => {
    this.items = this.items.filter(_ => _.place !== place);
    this.fillNewPlaces();
  };

  onChangeItemPlace = (item: InvoiceItem, newPlace: number) => {
    if (newPlace > this.items.length - 1 || newPlace < 0) {
      return;
    }
    const newItems = this.items;
    newItems.splice(item.place, 1);
    newItems.splice(newPlace, 0, item);
    this.items = newItems;
    this.fillNewPlaces();
  };

  fillNewPlaces = () => {
    this.items = this.items
      .map((_: InvoiceItem, i) => {
        _.place = i;
        return _;
      })
      .sort((a, b) => a.place - b.place);
  };
}

export class InvoiceStore {
  invoiceIframeRef = createRef<HTMLIFrameElement>();
  pdfUrl?: string;
  form: InvoiceForm = new InvoiceForm(this);

  constructor() {
    makeAutoObservable(this);
    this.createInvoice();
    observe(this.form, () => {
      this.updateInvoice();
    });
  }

  updateInvoice = debounce(() => {
    this.createInvoice();
  }, 500);

  createInvoice = async () => {
    this.pdfUrl = (await createInvoice(this.form)) ?? '';
    console.debug(this.pdfUrl);
  };

  savePdf = () => {
    if (!this.pdfUrl) return;
    const link = document.createElement('a');
    link.href = this.pdfUrl;
    link.download = 'invoice.pdf';
    document.body.appendChild(link);
    link.click();
  };
}
