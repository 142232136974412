import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import styles from './ContextMenu.module.scss';

export type ContextMenuFloatingProps = HTMLAttributes<HTMLDivElement>;

export const ContextMenu = forwardRef<HTMLDivElement, {
	children: ReactNode;
} & ContextMenuFloatingProps>(function ContextMenu({
	children,
	...props
}, forwardedRef) {
	return (
		<div
			ref={forwardedRef}
			className={styles.ContextMenu}
			{...props}
		>
			{children}
		</div>
	);
});
